import commonMutations from '@/common/store/mutations'
import generateKey from '@/utils/generateKey'

const customMutations = {
  NEW_SUBSCRIPTION (state, payload) {
    state.newSubscription = { ...state.newSubscription, key: generateKey(), show: payload, errors: {} }
  },
  NEW_SUBSCRIPTION_LISTS (state, payload) {
    state.newSubscription = { ...state.newSubscription, subscriptions: payload }
  },
  FETCH_USER_SUBSCRIPTION_LISTS (state, payload) {
    state.subscriptions = { ...state.subscriptions, lists: payload }
  },
  IS_USER_SUBSCRIPTION_FETCHING (state, payload) {
    state.subscriptions = { ...state.subscriptions, isFetching: payload }
  },
  UPDATE_USER_SUBSCRIPTION_PAGE_DATA (state, payload) {
    state.subscriptions = {
      ...state.subscriptions,
      pageData: {
        ...state.pageData,
        ...payload
      }
    }
  },
  UPDATE_USER_SUBSCRIPTION_SORT_DATA (state, payload) {
    state.subscriptions = {
      ...state.subscriptions,
      sortData: {
        ...state.sortData,
        ...payload
      }
    }
  },
  RESEND_REQUESTING (state, payload) {
    state.resendRequesting = payload
  }
}

export default Object.assign(
  {},
  commonMutations,
  customMutations
)
