import commonActions from '@/common/store/actions'
import axios from '@/utils/axios'

const rootDispatch = { root: true }
const customActions = {
  async loadSubscriptionLists ({ commit, dispatch }) {
    const { type, payload } = await axios({ url: 'subscriptions/subscriptionOptions', method: 'GET' })
    if (type === 200) {
      commit('NEW_SUBSCRIPTION_LISTS', payload)
    } else {
      dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
    }
  },
  async loadUserSubscriptionLists ({ commit, state, dispatch }) {
    const { pageData: { currentPage, perPage }, sortData: { column, direction } } = state.subscriptions
    commit('IS_USER_SUBSCRIPTION_FETCHING', true)
    const { type, payload } = await axios({
      url: 'user/subscriptions',
      method: 'GET',
      params: {
        page: currentPage,
        perPage,
        sortColumn: column,
        sortDirection: direction
      }
    })
    commit('IS_USER_SUBSCRIPTION_FETCHING', false)
    if (type === 200) {
      const { data, meta } = payload
      commit('FETCH_USER_SUBSCRIPTION_LISTS', data)
      const pageDataPayload = {
        currentPage: meta.current_page,
        perPage: parseInt(meta.per_page, 10),
        totalPage: Math.ceil(meta.total / parseInt(meta.per_page, 10)),
        total: meta.total || 0,
        to: meta.to || 0,
        from: meta.from || 0
      }
      commit('UPDATE_USER_SUBSCRIPTION_PAGE_DATA', pageDataPayload)
      return
    }
    dispatch('addNotification', { type: 'error', message: payload.message }, rootDispatch)
  },
  async resendVerification ({ dispatch, commit }) {
    commit('RESEND_REQUESTING', true)
    const { type, payload: { message } } = await axios({ url: 'verification/resend', method: 'GET' })
    commit('RESEND_REQUESTING', false)
    dispatch('addNotification', { type: (type === 200 ? 'success' : 'error'), message }, rootDispatch)
  },
  async addUserSubscription ({ commit, dispatch }, payload) {
    const { type, payload: returnPayload } = await axios({ url: 'user/subscriptions', method: 'POST', data: payload })
    if (type === 200) {
      commit('ADD_LOGGED_USER_DATA', returnPayload.data, rootDispatch)
      commit('NEW_SUBSCRIPTION', false)
      dispatch('loadUserSubscriptionLists')
      dispatch('addNotification', { type: 'success', message: 'Subscription successfully processed!' }, rootDispatch)
    }
  },
  changeUserSubscriptionPage ({ commit, dispatch }, payload) {
    commit('UPDATE_USER_SUBSCRIPTION_PAGE_DATA', payload)
    dispatch('loadUserSubscriptionLists')
  },
  changeUserSubscriptionSort ({ commit, dispatch }, payload) {
    commit('UPDATE_USER_SUBSCRIPTION_SORT_DATA', payload)
    dispatch('loadUserSubscriptionLists')
  }
}

export default Object.assign(
  {},
  commonActions,
  customActions
)
