import commonState from '@/common/store/state'

import { entity, entitySingle } from '../entity'

const customState = {
  entity,
  entitySingle,
  subscriptions: {
    lists: [],
    pageData: {
      currentPage: 1,
      totalPage: 0,
      perPage: 25,
      from: 0,
      to: 0,
      total: 0
    },
    sortData: {
      column: 'created_at',
      direction: 'desc'
    }
  },
  newSubscription: {
    key: '',
    show: false,
    subscriptions: []
  },
  resendRequesting: false
}

export default Object.assign(
  {},
  commonState,
  customState
)
