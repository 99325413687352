<template>
  <CCard>
    <CCardHeader>
      My Subscriptions
      <CButtonGroup
        class="float-right"
      >
        <CButtonGroup
            class="float-right"
          >
            <!-- <CButton size="sm" color="primary" @click="newSubscription">
              <CIcon name="cil-plus" /> New Subscription
            </CButton> -->
            <CButton size="sm" color="success" @click="loadUserSubscriptionLists">
              <CIcon name="cil-action-redo" /> Refresh
            </CButton>
          </CButtonGroup>
      </CButtonGroup>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="true"
        :striped="true"
        :border="true"
        :small="true"
        :fixed="true"
        :items="lists"
        :fields="$options.fields"
        :dark="false"
        :loading="fetching"
        :sorter="{ external: true }"
        :sorterValue="sorterValue"
        @update:sorter-value="updateSort"
      >
        <template #subscription="{item}">
          <td v-start-case>
            {{ item.subscription.name }}
          </td>
        </template>
        <template #is_current_subscription="{item}">
          <td class="text-center">
                <CBadge v-if="item.is_current_subscription" color="success">
                  Current
                </CBadge>
              </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { entity } from '../entity'
const fields = [
  'subscription',
  { key: 'created_at', label: 'Subscription Date' },
  { key: 'expiry_at', label: 'Expiry Date' },
  { key: 'is_current_subscription', label: 'Current Subscription' }
]
export default {
  name: 'UserSubscription',
  fields,
  computed: {
    ...mapState({
      lists: state => state[entity].subscriptions.lists,
      sortData: state => state[entity].subscriptions.sortData,
      fetching: state => state[entity].subscriptions.isFetching
    }),
    sorterValue () {
      const { column, direction } = this.sortData
      return {
        column,
        asc: direction === 'asc'
      }
    }
  },
  methods: {
    ...mapActions({
      loadUserSubscriptionLists: `${entity}/loadUserSubscriptionLists`,
      changeUserSubscriptionSort: `${entity}/changeUserSubscriptionSort`
    }),
    newSubscription () {
      this.$store.commit(`${entity}/NEW_SUBSCRIPTION`, true)
    },
    updateSort ({ asc, column }) {
      this.changeUserSubscriptionSort({ column, direction: asc ? 'asc' : 'desc' })
    }
  },
  mounted () {
    this.loadUserSubscriptionLists()
  }
}
</script>
