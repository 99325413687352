<template>
  <CCard>
    <CCardHeader>
      User Details
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <div class="form-group">
            <label>Name</label>
            <strong class="d-block border px-3 py-2">{{ user.name }} </strong>
          </div>
          <div class="form-group">
            <label>Email</label>
            <strong class="d-block border px-3 py-2">{{ user.email }} </strong>
          </div>
          <div class="form-group">
            <label>Is Verified</label>
            <strong class="d-block py-2">
              <CBadge class="p-2 mr-2" :color="user.is_verified ? 'success' : 'danger'">
                {{ user.is_verified ? 'Verified' : 'Unverified' }}
              </CBadge>
              <CButton
                v-if="!user.is_verified"
                :color="resendRequesting ? 'secondary' : 'primary'"
                size="sm"
                @click="resendVerification"
              >
                Resend Verification{{' '}}
                <CSpinner
                  v-if="resendRequesting"
                  color="info"
                  size="sm"
                />
              </CButton>
            </strong>
          </div>
          <div class="form-group">
            <label>Last Login</label>
            <strong class="d-block border px-3 py-2">{{ user.last_login_at }} </strong>
          </div>
          <div class="form-group">
            <label>Registered</label>
            <strong class="d-block border px-3 py-2">{{ user.created_at }} </strong>
          </div>
          <div v-if="!user.is_admin && user.is_verified" class="form-group">
            <label class="d-block">Current Subscription</label>
            <div v-if="user.current_subscription">
              <CBadge v-start-case color="success">
                {{ user.current_subscription.name }}
              </CBadge>{{' '}}
              <i class="small">(Expires {{ user.current_subscription.expiry_at }})</i>
            </div>
            <CButton v-if="!user.current_subscription" color="primary" size="sm" class="d-block" @click="newSubscription">New Subscription</CButton>
          </div>
          <div class="form-group">
            <label>Account Type</label>
            <div>
              <CBadge v-start-case color="primary" v-for="role in user.roles" :key="role.id">
                {{ role.name }}
              </CBadge>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState } from 'vuex'
import { entity } from '../entity'

export default {
  name: 'UserDetail',
  props: {
    user: {
      type: Object,
      defaultValue: {}
    }
  },
  computed: {
    ...mapState({
      resendRequesting: state => state[entity].resendRequesting
    })
  },
  methods: {
    async resendVerification () {
      if (!this.resendRequesting) {
        this.$store.dispatch(`${entity}/resendVerification`)
      }
    },
    newSubscription () {
      this.$store.commit(`${entity}/NEW_SUBSCRIPTION`, true)
    }
  }
}
</script>
