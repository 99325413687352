<template>
  <fragment>
    <CCard>
      <CCardHeader style="padding:0;">
        <CCarousel :animate="true" :interval="5000" :indicators="false">
          <CCarouselItem>
            <img src="@/assets/img/dogs.jpeg" class="d-block w-100 h-100 img-fluid" />
          </CCarouselItem>
          <CCarouselItem>
            <img src="@/assets/img/dogs-1.jpg" class="d-block w-100 h-100 img-fluid" />
          </CCarouselItem>
          <CCarouselItem>
            <img src="@/assets/img/dogs-2.jpg" class="d-block w-100 h-100 img-fluid" />
          </CCarouselItem>
        </CCarousel>
      </CCardHeader>
      <CCardBody>
        <p>We have developed an easy to use application for entering, storing and filling in AKC entry forms for AKC Hunt Tests.</p>
        <p>The app safely stores your kennels data, making it easy to fill in the entry forms for the Hunt Test Secretary. Once filled in they are printed in PDF format that can be saved to your local drive.</p>
        <p>This app was developed to give single users and kennels an easy way to track their Hunt Test and Field Trial Entries. The data, Kennel Information, Dogs, Events, Stakes and Secretaries with contact information. Once entered the information becomes pull down menus that make entering a dog in an event extremely easy.</p>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        Subscriptions
      </CCardHeader>
      <CCardBody>
        <CListGroup>
          <CListGroupItem>
            <div class="p-1" role="button" @click="collapse.masterSubscription = !collapse.masterSubscription">
              Master Subscription - $49
              <CIcon :name="collapse.masterSubscription ? 'cil-arrow-bottom' : 'cil-arrow-right'" class="float-right" />
            </div>
            <CCollapse class="px-1 bg-info rounded text-white" :show='collapse.masterSubscription'>
              With this subscription you can enter unlimited dogs and events.
            </CCollapse>
          </CListGroupItem>
        </CListGroup>
      </CCardBody>
    </CCard>
  </fragment>
</template>

<script>
export default {
  name: 'AppInfo',
  data () {
    return {
      collapse: {
        masterSubscription: false
      }
    }
  }
}
</script>
