<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="6">
          <user-detail
            :user="loggedUser"
          />
        </CCol>
        <CCol md="6" class="d-none d-md-block">
          <app-info
            :user="loggedUser"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <subscription
            v-if="!loggedUser.is_admin && loggedUser.is_verified"
            :user="loggedUser"
          />
        </CCol>
      </CRow>
    </CContainer>
    <!-- <new-subscription
      v-if="newSubscription.show"
      :key="newSubscription.key"
    /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppInfo from './components/AppInfo'
import UserDetail from './components/UserDetail'
import Subscription from './components/Subscription'
import { entity } from './entity'
import store from './store'
// import NewSubscription from './components/NewSubscription'

export default {
  components: {
    AppInfo,
    UserDetail,
    Subscription
    // 'new-subscription': NewSubscription
  },
  name: 'DashboardPage',
  title: 'Dashboard',
  computed: {
    ...mapState({
      loggedUser: state => state.session.loggedUser,
      newSubscription: state => state[entity].newSubscription
    })
  },
  beforeMount () {
    if (!this.$store.state[entity]) {
      this.$store.registerModule(entity, store)
    }
  }
}
</script>
